import React from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {useAuthDispatchContext, USER_LOGOUT} from "../../shared/contexts/auth-context";


const Logout = () => {
    const history = useHistory();

    const authDispatch = useAuthDispatchContext()

    React.useEffect(() => {
        (async () => {
            await axios.get(`${process.env.REACT_APP_API_BASE_URI}${process.env.REACT_APP_API_VERSION_URL}/auth/logout`)

            localStorage.removeItem('dop-token');
            localStorage.removeItem('dop-provider');

            const amplitude = window.amplitude;
            amplitude.getInstance().logEvent('Logout');
            amplitude.getInstance().setUserId(null);

            authDispatch({
                type: USER_LOGOUT
            })

            history.replace("/")
        })()
    })

    return null;
}

export default Logout;
