import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";

const styles = () => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap',
		width: '100%',
	},
	imgContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		padding: 10,
		'@media screen and (max-width: 950px)': {
			width: '100%',
			justifyContent: 'center',
		},
	},
	banner: {
		display: 'flex',
		justifyContent: 'center',
		height: 150,
		maxWidth: 150,
		overflow: 'hidden',
	},
	img: {
		height: '100%',
		width: 'auto',
	},
	top: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},
	middle: {
		display: 'flex',
		flexDirection: 'column',
		// justifyContent: 'flex-end',
	},
	bottom: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		paddingBottom: 10,
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		margin: '0px 10px',
		paddingRight: 30,
		minHeight: 170,
		'@media screen and (min-width: 950px)': {
			width: 560,
		},
		'@media screen and (max-width: 950px)': {
			width: '100%',
			maxWidth: '100%',
			marginTop: '12px',
			textAlign: 'center',
		}
	},
	title: {
		fontSize: 'min(max(22px, 4.5vw), 44px)',
		fontWeight: 'bold',
		letterSpacing: '2px',
		textTransform: 'capitalize',
		color: '#FFF',
		marginBottom: 6,
	},
	description: {
		fontSize: 'min(max(14px, 3vw), 16px)',
		letterSpacing: '2px',
		color: '#FFF',
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: 'min(max(130px, 14vw), 220px)',
		height: 'auto',
		alignItems: 'flex-start',
		justifyContent: 'space-around',
		padding: 0,
		margin: 10,
		'@media screen and (max-width: 950px)': {
			alignItems: 'center',
			width: '100%',
		}
	},
	button: {
		margin: 0,
		marginTop: 8,
		padding: 6,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 0,
		width: '100%',
		maxWidth: 220,
		height: 'auto',
		minHeight: '50px',
		backgroundColor: 'transparent',
		border: '3px solid #FFF',
		color: '#FFF',
		fontWeight: 'bold',
		fontSize: 'min(max(12px, 1vw), 16px)',
		wordWrap: 'wrap',
		textAlign: 'center',
	},
	linkContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '8px 0px 0px 0px',
		'@media screen and (max-width: 950px)': {
			alignItems: 'center',
		},
		color: '#FFF'
	},
	link: {
		fontSize: 'min(max(14px, 2vw), 18px)',
		color: '#FFF',
	},
	divider: {
		backgroundColor: '#FFF',
		width: '100%',
		height: 2,
		margin: '10px 0px',
	},
	lastPlayed: {
		textAlign: 'right',
		color: 'rgba(255,255,255,0.6)',
	},
});

const G = props => {

	let {
		classes,
		playWithGoogle,
		playWithAlexa,
		playWithZoom,
		getLastPlayed,
		play_alexa,
		play_google,
		play_zoom,
		id,
		image,
		title,
		description,
		collectibles,
		collectibles_link,
		getNumHotCards
	} = props;

	const { t } = useTranslation();

	return (
			<div className={classes.container}>
				<div className={classes.imgContainer}>
					<div className={classes.banner}>
						<img className={classes.img} src={image} title={id} alt={image}/>
					</div>
				</div>
				<div className={classes.info}>
					<div className={classes.top}>
						<Typography variant="h5" className={classes.title}>{title}</Typography>
					{/* </div>
					<div className={classes.middle}> */}
						<Typography variant="body1" className={classes.description}>{description}</Typography>
						<Typography variant="caption" className={classes.lastPlayed}>{getLastPlayed()}</Typography>
					</div>
					<div className={classes.bottom}>
						{collectibles_link && (<div className={classes.linkContainer}>
							<Link to={collectibles_link} className={classes.link}>{collectibles}</Link>
						</div>)}
						{id === 'hot' && getNumHotCards() > 0 && (<div className={classes.linkContainer}>
							{t('portal.hot.cards_label')}: {getNumHotCards()}
						</div>)}
					</div>
				</div>
				<div className={classes.buttonContainer}>
					{playWithAlexa &&
						<Button
							className={classes.button}
							onClick={() => playWithAlexa(id)}
						>
							{play_alexa}
						</Button>
					}
					{playWithGoogle &&
						<Button
							className={classes.button}
							onClick={() => playWithGoogle(id)}
						>
							{play_google}
						</Button>
					}
					{playWithZoom &&
						<Button
							className={classes.button}
							onClick={() => playWithZoom(id)}
						>
							{play_zoom}
						</Button>
					}
				</div>
			</div>
	)
};

G.propTypes = {
	classes: PropTypes.object,
	id: PropTypes.string,
	image: PropTypes.node, // image already imported
	title: PropTypes.string,
	description: PropTypes.string,
	collectibles_link: PropTypes.string,
	collectibles: PropTypes.string,
	play_alexa: PropTypes.string,
	play_google: PropTypes.string,
	playWithAlexa: PropTypes.any, // function
	playWithGoogle: PropTypes.any, // function
	getLastPlayed: PropTypes.any, // function
	getNumHotCards: PropTypes.any // function
};

const Game = withStyles(styles)(G)

export default Game;