import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
	container: {
			display: 'flex',
			width: '100%',
	},
	left: {
			display: 'flex',
			alignItems: 'center',
	},
	right: {
			display: 'flex',
			alignItems: 'center',
	},
});

const HG = props => {
	let { left, right, classes } = props;
	return (
			<Grid container spacing={4} className={classes.container}>
					<Grid item xs={5} className={classes.left}>
							{left}
					</Grid>
					<Grid item xs={7} className={classes.right}>
							{right}
					</Grid>
			</Grid>
	)
};

const HeaderContainer = withStyles(styles)(HG);

export default HeaderContainer;