import React from 'react';
import './LoginPage.scss';
import Header from '../../shared/components/header/Header';
import Footer from '../../shared/components/footer/Footer';
import FeaturesContainer from '../../shared/components/features/FeaturesContainer';
import LoginContainer from './LoginContainer';
import unlock_rewards from '../../assets/landing-page/unlock-rewards.png';
import find_amazing_games from '../../assets/landing-page/find-amazing-games.png';
import join_the_community from '../../assets/landing-page/join-the-community.png';

import {useTranslation} from 'react-i18next';

const LoginPage = () => {

    const { t } = useTranslation();
    const amplitude = window.amplitude;

    const loginWithAmazon = () => {
        amplitude.getInstance().logEvent('StartLogin', {
            login_option: 'amazon'
        });

        window.location = process.env.REACT_APP_API_BASE_URI + process.env.REACT_APP_API_VERSION_URL + '/connect/amazon'
    };

    const loginWithGoogle = () => {
        amplitude.getInstance().logEvent('StartLogin', {
            login_option: 'google'
        });

        window.location = process.env.REACT_APP_API_BASE_URI + process.env.REACT_APP_API_VERSION_URL + '/connect/google'
    };

    let features = [
        { data: { title: t('features.unlock.title'), description: t('features.unlock.description'), backgroundColor: 'transparent', color: 'initial', textColor: 'initial', image: unlock_rewards }, height: 360 },
        { data: { title: t('features.discover.title'), description: t('features.discover.description'), backgroundColor: 'transparent', color: 'initial', textColor: 'initial', image: find_amazing_games }, height: 360 },
        { data: { title: t('features.join.title'), description: t('features.join.description'), backgroundColor: 'transparent', color: 'initial', textColor: 'initial', image: join_the_community }, height: 360 },
    ];

    return (
        <div className="dop-login-page">
            <Header/>
            <LoginContainer amazonLogin={loginWithAmazon} googleLogin={loginWithGoogle} />
            <FeaturesContainer values={features} />
            <Footer/>
        </div>
    )
}

export default LoginPage;
