import React from 'react';
import './AccountLinkPage.scss';
import Paper from "@material-ui/core/Paper";
import {useLocation, useParams} from "react-router-dom";
import DoppioLogo from '../../assets/account-link/logo_doppio.png';
import ArpeggioLogo from '../../assets/account-link/logo_vortex.png';
import ArrowsLogo from '../../assets/account-link/logo_arrows_2.png';
import {Trans, useTranslation} from "react-i18next";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const AccountLinkPage = () => {
    const { t } = useTranslation();
    const query = useQuery();
    const {linkTo} = useParams();

    const apiURI = process.env.REACT_APP_API_BASE_URI;
    const versionURI = process.env.REACT_APP_API_VERSION_URL;

    const getLocation = (provider, linkTo) => {
        switch (linkTo) {
            case 'alexa': {
                const redirect_uri = query.get('redirect_uri');
                const path = redirect_uri.split("://")[1];
                let safePath = path.split('/').join('|');
                safePath = safePath.split('?vendorId=')[0];
                const vendorId = redirect_uri.split('?vendorId=')[1];

                const payload = {
                    state: query.get('state'),
                    path: safePath,
                    vendorID: vendorId
                };

                const location = apiURI +
                    versionURI +
                    `/connect/${provider}` +
                    `?callback=` +
                    `${encodeURIComponent(
                        `${apiURI}${versionURI}` +
                        `/account/link/${linkTo}` +
                        `/${query.get('client_id')}` +
                        `?state=${encodeURIComponent( btoa(JSON.stringify(payload)) )}`
                    )}`

                return location;
            }
            case 'google': 
            case 'jstw-zoom': {
                const redirect_uri = query.get('redirect_uri');
                const path = redirect_uri.split("://")[1];
                let safePath = path.split('/').join('|');

                const payload = {
                    state: query.get('state'),
                    path: safePath
                };

                const location = apiURI +
                    versionURI +
                    `/connect/${provider}` +
                    `?callback=` +
                    `${encodeURIComponent(
                        `${apiURI}${versionURI}` +
                        `/account/link/${linkTo}` +
                        `/${query.get('client_id')}` +
                        `?state=${encodeURIComponent(btoa(JSON.stringify(payload)))}`
                    )}`;

                return location;
            }
            default:
                console.warn('Unknown linkTo value', {linkTo})
                return '';
        }
    }

    const loginWithAmazon = () => {
        window.location.replace(getLocation('amazon', linkTo));
    };

    const loginWithGoogle = () => {
        window.location.replace(getLocation('google', linkTo));
    };

    const getTitleText = () => {
        return (
            <h1>
                <Trans i18nKey={`page_title_${query.get('client_id')}`} />
            </h1>
        );
    };

    const getPermissionsText = () => {
        return (
            <>
                <Trans i18nKey={`permissions.${query.get('client_id')}_title`} />
                <ul>
                    <li>
                        <Trans i18nKey={"permissions.list_line_1"}/>
                    </li>
                    <li>
                        <Trans i18nKey={"permissions.list_line_2"}/>
                    </li>
                </ul>
                <hr/>
                <p>
                    <Trans i18nKey={"info_line_1"}/>
                </p>
                <p>
                    <Trans i18nKey={"info_line_2"}/>
                </p>
            </>
        )
    }

    return (
        <div className="dop-account-link-page">
            <Paper className="dop-account-link">
                <div className="dop-account-link__logos-container">
                    <div className="logo logo__doppio">
                        <img src={DoppioLogo} alt="Doppio Games"/>
                    </div>
                    <div className="logo logo__arrows">
                        <img src={ArrowsLogo} alt="Connect to:"/>
                    </div>
                    <div className="logo logo__game">
                        {
                            query.get('client_id') === 'arpeggio' ? (
                                <img src={ArpeggioLogo} alt="The Vortex"/>
                            ) : null
                        }
                    </div>
                </div>
                <div className="dop-account-link__title">
                    {getTitleText()}
                </div>
                <div className="dop-account-link__body">
                    {getPermissionsText()}
                </div>
                <div className="dop-account-link__providers">
                    <a href={getLocation('amazon', linkTo)} className="provider provider--amazon" onClick={loginWithAmazon}>
                        <i className="provider__icon provider--amazon__icon"/>
                        <div className="provider__label"><Trans i18nKey={"permissions.login_with_amazon"}/></div>
                    </a>
                    <a href={getLocation('google', linkTo)} className="provider provider--google" onClick={loginWithGoogle}>
                        <i className="provider__icon provider--google__icon"/>
                        <div className="provider__label"><Trans i18nKey={"permissions.login_with_google"}/></div>
                    </a>
                </div>
                <div className="dop-account-link__privacy">
                    <p>
                        <Trans i18nKey="privacy_links">
                            Please review our <a href={t('privacy_url')}>privacy policy</a> and <a href={t('tos_url')}>terms of service</a>.
                        </Trans>
                    </p>
                </div>
            </Paper>
        </div>
    )
}

export default AccountLinkPage;
