import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '../icons/CloseIcon';

const styles = () => ({
	snackbar: {
		backgroundColor: 'rgba(32,26,22,0.8)'
	},
});

const Alert = props => {
	let { open, message, closeAction, actionComponent, classes, setOpen } = props;
	// let [alert_open, setOpen] = useState(open);

	const handleClose = (event, reason) => {
		if (closeAction) closeAction(event, reason);
		else if (reason === 'clickaway') return;
		setOpen(false);
	};

	return (
		<Snackbar
			ContentProps={{
				"aria-describedby": 'alert',
				className: classes.snackbar,
			}}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			message={message || ''}
			action={actionComponent || (
				<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
					<CloseIcon fontSize="small" className={classes.icon}/>
				</IconButton>
			)}
		/>
	);
};

export default withStyles(styles)(Alert);
