import React from 'react';
import './HomePage.scss';
import Header from "../../shared/components/header/Header";
import Jumbo from "../../shared/components/jumbo/Jumbo";
import GamesContainer from "../../shared/components/games-container/GamesContainer";
import Footer from "../../shared/components/footer/Footer";
import vortex_icon from '../../assets/games/vortex_icon.png';
import {useTranslation} from "react-i18next";

const HomePage = () => {
    const { t } = useTranslation();
    let games = [
        {
            id: 'arpeggio',
            image: vortex_icon,
            title: t('portal.arpeggio.title'),
            description: t('portal.arpeggio.description'),
            collectibles_link: '/vortex-collectibles',
            collectibles: t('portal.arpeggio.collectibles'),
            zoom: false,
            google: false
        }
    ];
    return (
        <div className="dop-home">
            <Header/>
            <Jumbo text={"Games Portal"}/>
            <section>
                {/* className="dop-home__main-container"> */}
               <GamesContainer games={games}/>
            </section>
            <Footer/>
        </div>
    )
}

export default HomePage;
