import React, {useEffect} from 'react';
import './CollectiblesContainer.scss';
import {useAuthStateContext} from "../../contexts/auth-context";
import {useTranslation} from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import Collectible from './Collectible';
import { Typography } from '@material-ui/core';

const styles = () => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 5fr))',
        width: '100%',
    },
    imgContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // '@media screen and (max-width: 900px)': {
        //     width: '100%',
		// },
    },
    text: {
        color: '#FFF',
        fontSize: 'min(max(14px, 6vw), 24px)',
        fontStyle: 'italic',
        paddingLeft: 'min(max(0px, 5vw), 90px)',
        textAlign: 'center',
        marginBottom: '2em'
    },
});

const CollectiblesContainer = (props) => {

    let { classes, collectibles } = props;

    const { user } = useAuthStateContext();
    const { t } = useTranslation();
    const theCollectibles = collectibles || [];

    useEffect(() => {
        // console.log('games user', user);
    }, [user]);

    return (
        <div>
            <div className={classes.textContainer}>
                <Typography variant="body2" className={classes.text}>
                    {t(theCollectibles.length < 40 ? 'portal.arpeggio.some_found' : 'portal.arpeggio.all_found', { count: theCollectibles.length })}
                </Typography>
            </div>
            <div className={classes.container}>
            {theCollectibles.map((c, i) => {
                console.log("hi", {c, i});
                return (
                    <div className={classes.imgContainer} key={i}>
                        <Collectible image={`/vortex-collectibles/${c}.png`} />
                    </div>
                );
                })}
            </div>
        </div>
    )
}

export default withStyles(styles)(CollectiblesContainer);
