import React from 'react';
import './Loader.scss';
import CircularProgress from "@material-ui/core/CircularProgress";

const Loader = () => {
    return (
        <div className="dop-loader">
            <CircularProgress/>
        </div>
    )
}

export default Loader;
