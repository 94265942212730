import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
	card: {
		margin: '0 6px',
		marginBottom: 40,
		padding: 0,
		backgroundColor: 'transparent',
		boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
	},
	header: {},
	media: {
		margin: 'auto',
		padding: 0,
		// height: 'min(max(280px, 40vw), 468px)',
	},
	content: {},
	actions: {},
});

const Collectible = props => {
	let {
		classes,
		avatar,
		header_action,
		title,
		subheader,
		content,
		actions,
		image,
		image_title,
	} = props;
	return (
		<Card className={classes.card}>
			{(avatar || header_action || title || subheader) && (
				<CardHeader
					className={classes.header}
					avatar={avatar} // component
					action={header_action} // component
					title={title} // string
					subheader={subheader} // string
				/>
			)}
			{image && (
				<CardMedia
					component="img"
					className={classes.media}
					image={image}
					title={image_title}
					height={'min(max(280px, 40vw), 468px)'}
				/>
			)}
			{content && (
				// content must be a component
				<CardContent className={classes.content}>
					{content} 
				</CardContent>
			)}
			{actions && (
				// actions must be a component
				<CardActions className={classes.actions}>
					{actions}
				</CardActions>
			)}
		</Card>
	)
};

export default withStyles(styles)(Collectible);
