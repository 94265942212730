import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useAuthStateContext} from "../../contexts/auth-context";
import {useTranslation} from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Alert from "../alert/Alert";

const styles = () => ({
	container: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'flex-start',
		marginBottom: 40,
		'@media screen and (max-width: 1080px)': {
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	name: {
		display: 'flex',
		alignItems: 'center',
		paddingRight: 50,
	},
	userContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '30vw',
		marginBottom: 20,
		'@media screen and (max-width: 1080px)': {
			width: '100%'
		},
	},
	user: {
		color: '#FFF',
		fontStyle: 'italic',
		fontSize: 24,
	},
	userImage: {
		height: 'min(max(122px, 12vw), 170px)',//'10vw',
		width: 'min(max(122px, 12vw), 170px)',//'10vw',
	},
	caption: {
		color: '#FFF',
		fontSize: '12px',
	},
	gallery: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '40vw',
		'@media screen and (max-width: 1350px)': {
			width: '50vw'
		},
		'@media screen and (max-width: 1080px)': {
			width: '60vw'
		},
		'@media screen and (max-width: 900px)': {
			width: '80vw'
		},
		'@media screen and (max-width: 450px)': {
			width: '100vw'
		},
	},
	galleryTitle: {
		color: '#FFF',
		fontSize: 28,
	},
	galleryContainer: {
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
		width: '100%',
	},
	galleryImage: {
		height: 'min(max(122px, 12vw), 170px)',//'10vw',
		width: 'min(max(122px, 12vw), 170px)',//'10vw',
		margin: 8,
		transition: 'height .3s ease, width .3s ease',
		'&:hover': {
			transform: 'scale(1.1)',
		}
	},
	imgContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
});

const ProfileContainer = (props) => {

		let { classes, profile_data, user_icon } = props;
		const { user } = useAuthStateContext();
		let image_name = getProfileImage(user);
		let [user_image, setImage] = useState(image_name || user_icon);
		let [open, setOpen] = useState(false);
		let [message, setMessage] = useState('');
		const { t } = useTranslation();
		
		async function doSaveProfileImage(image) {
			try {
				const token = localStorage.getItem('dop-token');
				const provider = localStorage.getItem('dop-provider');
				if(!token || !provider) {
					throw new Error("Missing token/provider");
				}
				const response = await axios.post(`${process.env.REACT_APP_API_BASE_URI}${process.env.REACT_APP_API_VERSION_URL}/account/properties/portal`, {
					properties: {
						profileImage: image
					},
					provider,
					client: 'french'
				}, {
					headers: {
						'Authorization': `Bearer ${token}`
					}
				});
				if(response.status >= 200 && response.status <= 299) {
					if (response && response.data) {
						// console.log('doSaveProfileImage OK', response.data);
					} else {
						console.log('doSaveProfileImage OK - no response data');
					}
					return true;
				} else {
					console.warn(`doSaveProfileImage: Save image failed with status ${response.status} - ${response.data}`);
					return false;
				}
			} catch(err) {
				console.error("Save profile image failed", err);
				return false;
			}
		};

		function getProfileImage(user) {
			const profileImage = (user.gameProperties && user.gameProperties.french) ? user.gameProperties.french.profileImage : null;
			if(profileImage) {
				return profileImage;
			} else {
				return 'hat_0';
			}
		}

		let setUserAvatar = (image_name) => {
			const amplitude = window.amplitude;
			amplitude.getInstance().logEvent('AvatarSelect', {
				game_id: 'french',
				avatar_id: image_name
			});
			
			setImage(image_name);
			doSaveProfileImage(image_name)
			.then(() => {
				setMessage(t('portal.french.alert_success'));
				setOpen(true);
			})
			.catch(err => {
				setMessage(t('portal.french.alert_error'));
				setOpen(true);
			})
		}

    useEffect(() => {
        // console.log('games user', user);
	}, [user]);
	
	useEffect(() => {
		const amplitude = window.amplitude;
        amplitude.getInstance().logEvent('GoToPage', {
			page_id: 'french_profile'
		});
	}, []);

    return (
			<>
			<Alert message={message} setOpen={setOpen} open={open} />
			<div className={classes.container}>
				<div className={classes.userContainer}>
					<Typography variant="h5" className={classes.user}>{user.name}</Typography>
					<Avatar
						className={classes.userImage}
						alt={user.name}
						src={user_image && `/voicequest-avatars/${user_image}.png`}
					/>
					<Typography variant="caption" className={classes.caption}>{t('profile.icon')}</Typography>
				</div>
				<div className={classes.gallery}>
					<Typography variant="h5" className={classes.galleryTitle}>{t('profile.gallery')}</Typography>
					<div className={classes.galleryContainer}>
						{(profile_data || []).map((c, i) => (
							<div className={classes.imgContainer} key={i}>
								<Avatar
									onClick={() => setUserAvatar(c)}
									className={classes.galleryImage}
									alt={c}
									src={`/voicequest-avatars/${c}.png`} />
							</div>
						))}
					</div>
				</div>
			</div>
			</>
    )
}

export default withStyles(styles)(ProfileContainer);
