import React from 'react';
import './App.scss';
import LoginPage from "./pages/login/LoginPage";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import LoginCallback from "./pages/login/LoginCallback";
import Logout from "./pages/login/Logout";
import PrivateRoute from "./shared/routes/PrivateRoute";
import useCurrentUser from "./shared/hooks/useCurrentUser";
import AccountLinkPage from "./pages/account-link/AccountLinkPage";
import ArpeggioCollectibles from './pages/arpeggio-collectibles/ArpeggioCollectibles';
import ProfilePage from './pages/profile/ProfilePage';

const App = () => {
    const token = localStorage.getItem('dop-token');
    const provider = localStorage.getItem('dop-provider');
    const [user, userLoading] = useCurrentUser(token, provider);

    return (
        <div className="dop-portal">
            <BrowserRouter>
                <Switch>
                    <Route path="/account-link/:linkTo">
                        <AccountLinkPage/>
                    </Route>
                    <Route exact path="/login">
                        <LoginPage/>
                    </Route>
                    <Route exact path="/login/callback">
                        <LoginCallback/>
                    </Route>
                    <Route exact path={"/logout"}>
                        <Logout/>
                    </Route>
                    <PrivateRoute exact path="/" component={HomePage} auth={user} loading={userLoading}/>
                    <PrivateRoute exact path="/vortex-collectibles" component={ArpeggioCollectibles} auth={user} loading={userLoading}/>
                    <PrivateRoute exact path="/profile" component={ProfilePage} auth={user} loading={userLoading}/>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
