import React, {useEffect} from 'react';
import './ArpeggioCollectibles.scss';
import Header from "../../shared/components/header/Header";
import Jumbo from "../../shared/components/jumbo/Jumbo";
import BackIcon from "../../shared/components/icons/BackIcon";
import Footer from "../../shared/components/footer/Footer";
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import CollectiblesContainer from '../../shared/components/collectibles-container/CollectiblesContainer';
import {useAuthStateContext} from "../../shared/contexts/auth-context";
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';

const styles = () => ({
	container: {
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundColor: 'transparent'
	},
	innerContainer: {
		display: 'flex',
		alignItems: 'center',
        justifyContent: 'space-around',
	},
	back: {
		color: '#FFF',
		fontSize: 'min(max(26px, 6vw), 60px)',
    },
    textContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
	text: {
        color: '#FFF',
        fontSize: 'min(max(14px, 6vw), 24px)',
        fontStyle: 'italic',
        paddingLeft: 'min(max(0px, 5vw), 90px)',
    },
    link: {
    },
});

const ArpeggioCollectibles = (props) => {
    const { user } = useAuthStateContext();
    const { t } = useTranslation();
    let { classes } = props;

	useEffect(() => {
		const amplitude = window.amplitude;
        amplitude.getInstance().logEvent('GoToPage', {
			page_id: 'arpeggio_collectibles'
		});
    }, []);
    
    const getCollectibles = () => {
        let allCollectibles = (user.gameProperties && user.gameProperties.arpeggio && user.gameProperties.arpeggio.collectibles) ? 
                user.gameProperties.arpeggio.collectibles.filter(c => c.startsWith("collectible_")) :
                        [];

        return allCollectibles;
    }

    return (
        <div className={classes.container}>
            <Header/>
            <div className={classes.innerContainer}>
				<Link to={'/'} className={classes.link}>
					<IconButton aria-label="back">
						<BackIcon className={classes.back}/>
					</IconButton>
				</Link>
				<Jumbo text={t('portal.arpeggio.collectibles_title')}/>
				<div />
			</div>
            <section>
                {getCollectibles() && getCollectibles().length > 0
                    ? (
                        <CollectiblesContainer collectibles={getCollectibles() || []} />
                    ) : (
                        <div className={classes.textContainer}>
                            <Typography variant="body2" className={classes.text}>
                                {t('portal.arpeggio.nothing_found')}
                            </Typography>
                        </div>
                    )}
            </section>
            <Footer/>
        </div>
    )
}

export default withStyles(styles)(ArpeggioCollectibles);
