import React from 'react';
import Header from "../../shared/components/header/Header";
import Jumbo from "../../shared/components/jumbo/Jumbo";
import BackIcon from "../../shared/components/icons/BackIcon";
import Footer from "../../shared/components/footer/Footer";
import {useTranslation} from "react-i18next";
import ProfileContainer from '../../shared/components/profile-container/ProfileContainer';
import {useAuthStateContext} from "../../shared/contexts/auth-context";
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';

const styles = () => ({
	container: {
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundColor: 'transparent'
	},
	innerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
	},
	back: {
		color: '#FFF',
		fontSize: 'min(max(26px, 6vw), 60px)',
	},
	link: {
	},
});

const ProfilePage = (props) => {
	const { user } = useAuthStateContext();
	const { t } = useTranslation();
	let { classes } = props;

	const getProfileData = () => {
		let allProfiles = ['hat_0', 'hat_1', 'hat_2', 'hat_3', 'hat_4', 'hat_5', 'hat_6', 'hat_7', 'hat_8', 'hat_9', 'hat_10'];

		return allProfiles;
	}

	return (
		<div className={classes.container}>
			<Header/>
			<div className={classes.innerContainer}>
				<Link to={'/'} className={classes.link}>
					<IconButton aria-label="back">
						<BackIcon className={classes.back}/>
					</IconButton>
				</Link>
				<Jumbo text={t('portal.french.avatars_title')}/>
				<div />
			</div>
			<section>
				<ProfileContainer user_icon={user.avatar} profile_data={getProfileData() || []} />
			</section>
			<Footer/>
		</div>
	)
}

export default withStyles(styles)(ProfilePage);
