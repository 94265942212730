import React, { useEffect } from 'react';
import {GlobalProvider} from "./shared/contexts/global-context";
import {AuthProvider} from "./shared/contexts/auth-context";
import App from "./App";

const Root = () => {
    useEffect(() => {
        const amplitude = window.amplitude;
        amplitude.getInstance().init(process.env.REACT_APP_AMP_KEY);
        amplitude.getInstance().logEvent('PageLoaded');
    }, []);

    return (
        <GlobalProvider>
            <AuthProvider>
                <App/>
            </AuthProvider>
        </GlobalProvider>
    )
}

export default Root;
