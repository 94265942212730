import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from "axios";
import {
    useAuthDispatchContext,
    USER_AUTHENTICATED,
    USER_LOGOUT
} from "../../shared/contexts/auth-context";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const LoginCallback = () => {
    const history = useHistory();
    const query = useQuery();
    const authDispatch = useAuthDispatchContext()

    useEffect(() => {
        (async () => {
            const token = query.get('token');
            const provider = query.get('provider');

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URI}${process.env.REACT_APP_API_VERSION_URL}/auth/me`, {
                provider: provider
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            if (response && response.data) {
                localStorage.setItem('dop-token', token);
                localStorage.setItem('dop-provider', provider);
                authDispatch({
                    type: USER_AUTHENTICATED,
                    payload: response.data
                })

                // console.log("Response:", response.data);
                const amplitude = window.amplitude;
                if(response.data &&  response.data._id) {
                    amplitude.getInstance().setUserId(response.data._id);
                }
                amplitude.getInstance().logEvent('LoginComplete', {
                    login_option: provider
                });
                
                return history.replace('/');
            } else {
                authDispatch({
                    type: USER_LOGOUT
                })
                return history.replace('/login');
            }
        })()
    }, [authDispatch, history, query]);

    return null;
}

export default LoginCallback;
