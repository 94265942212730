import React from 'react';
import './Header.scss';
import {NavLink} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Logo from '../../../assets/voiceclub-logo.png';
import {useAuthStateContext} from '../../contexts/auth-context';
import {useTranslation} from 'react-i18next';
import HeaderContainer from './HeaderContainer';

const Header = () => {

    const { user } = useAuthStateContext();
    const { t } = useTranslation();

    return (
        <header className="dop-header">
            <HeaderContainer
                left={(
                    <NavLink className={"dop-header__brand"} to={"/"}>
                        <img src={Logo} alt="Doppio"/>
                    </NavLink>
                )}
                right={user ? (
                    <nav>
                        <ul>
                            <li>
                                <p>{user.name}</p>
                            </li>
                            <li>
                                <Button component={NavLink} className={"dop-header__btn"} to={"/logout"}>
                                    {t('portal.logout')}
                                </Button>
                            </li>
                        </ul>
                    </nav>
                ) : null}
            />                
        </header>
    )
}

export default Header;
