import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';
import AmazonIcon from '../../assets/icons/amazon.svg';
import GoogleIcon from '../../assets/icons/google.svg';

let styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgba(182,3,58,0.4)',
        color: 'rgba(255,255,255,1)',
        textAlign: 'center',
        padding: '40px 10px',
       // margin: '10px 0px',
    },
    gif: {
         backgroundImage: 'url("https://assets.doppio.games/banner%20animation%203.gif")',
         backgroundSize: 'cover',
         margin: '10px 0px 30px 0px',
     },
    title: {
        marginBottom: 18,
        fontSize: 'min(max(28px, 5vw), 60px)',
        // position: 'relative',
        // zIndex: 2
    },
    textContainer: {
        maxWidth: 600,
    },
    text: {
        marginBottom: 10,
        padding: 0,
        lineHeight: 1.8,
        fontSize: 'min(max(12px, 3vw), 20px)',
    },
    icon: {
        // width: 20,
        width: 'min(max(20px, 3vw), 32px)',
    },
    button: {
        width: 'min(max(280px, 40vw), 500px)',
        height: 'auto',
        maxHeight: '56px',
        minHeight: '40px',
        // '@media screen and (max-width: 936px)': {
        //     width: 200,
        // },
        backgroundColor: 'rgba(255,255,255,1)',
        padding: 12,
        margin: 16,
        textTransform: 'none',
    },
    buttonText: {
        fontSize: 'min(max(14px, 2vw), 20px)',
    },
    // linkContainer: {
    //     alignSelf: 'flex-end',
    // },
    // link: {
    //     color: '#FFF',
    //     fontSize: 8,
    // },
});

const L = props => {
    let { classes, amazonLogin, googleLogin } = props;
    const { t } = useTranslation();
    return (
        <div className={classes.gif}>
        <div className={classes.container}>
            <Typography variant="h2" className={classes.title}>
                {t('welcome')}
            </Typography>
            <div className={classes.textContainer}>
                <Typography variant="h5" className={classes.text}>
                    {t('welcome_subtitle')}
                </Typography>
                <Typography variant="h5" className={classes.text}>
                    {t('welcome_signup')}
                </Typography>
            </div>
            <Button
                startIcon={<img src={GoogleIcon} className={classes.icon} alt="login" />}
                variant="contained"
                className={classes.button}
                onClick={googleLogin}
            >
                <Typography variant="body2" className={classes.buttonText}>
                    {t('login_button.google')}
                </Typography>
            </Button>
            <Button
                startIcon={<img src={AmazonIcon} className={classes.icon} alt="login" />}
                variant="contained"
                className={classes.button}
                onClick={amazonLogin}
            >
                <Typography variant="body2" className={classes.buttonText}>
                    {t('login_button.amazon')}
                </Typography>
            </Button>
        </div>
        </div>
    );
    
};

const LoginContainer = withStyles(styles)(L);

export default LoginContainer;