import { useEffect } from 'react';
import axios from 'axios';
import {
    useAuthDispatchContext,
    useAuthStateContext,
    USER_AUTHENTICATED,
    USER_LOGOUT, USER_TOKEN_NOT_FOUND
} from "../contexts/auth-context";

export default function useCurrentUser() {
    const authDispatch = useAuthDispatchContext();
    const { user, userLoading, userError } = useAuthStateContext();
    const token = localStorage.getItem('dop-token');
    const provider = localStorage.getItem('dop-provider');
    const amplitude = window.amplitude;
    
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (token && provider) {
                if (!user) {
                    (async () => {
                        try {
                            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URI}${process.env.REACT_APP_API_VERSION_URL}/auth/me`, {
                                provider: provider
                            }, {
                                headers: {
                                    'Authorization': `Bearer ${token}`
                                }
                            })
                            if (response && response.data) {
                                authDispatch({
                                    type: USER_AUTHENTICATED,
                                    payload: response.data
                                })
                            } else {
                                localStorage.removeItem('dop-token');
                                localStorage.removeItem('dop-provider');
                                authDispatch({
                                    type: USER_LOGOUT
                                })
                            }
                        } catch (err) {
                            console.log('useCurrentUser err', err)
                            localStorage.removeItem('dop-token');
                            localStorage.removeItem('dop-provider');
                            authDispatch({
                                type: USER_LOGOUT
                            })
                        }
                    })()
                }
            } else {
                console.log('token not found')
                authDispatch({
                    type: USER_TOKEN_NOT_FOUND
                })
            }
        }, 0)

        return () => {
            clearTimeout(timeout)
        };

    }, [token, provider, user, authDispatch])

    // console.log("useCurrentUser:", user);
    if(user && user._id) {
        amplitude.getInstance().setUserId(user._id);
    }

    if(user && user.gameProperties) {
        if(user.gameProperties.arpeggio && user.gameProperties.arpeggio.collectibles
            && Array.isArray(user.gameProperties.arpeggio.collectibles))
        {
            const identify = new amplitude.Identify().set('vortex_collectibles_number', user.gameProperties.arpeggio.collectibles.length);
            amplitude.getInstance().identify(identify);
        }
    }

    return [
        user,
        userLoading,
        userError
    ]
}
