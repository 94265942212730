import React from "react";
import './Jumbo.scss';
import {useTranslation} from "react-i18next";

const Jumbo = ({ text }) => {
    const { t } = useTranslation();

    return (
        <div className="dop-jumbo">
            {/* <div className="dop-jumbo__overlay"/> */}
            <h1 className="dop-jumbo__text">{t(text)}</h1>
        </div>
    )
}

export default Jumbo;
