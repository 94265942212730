import React, { createContext, useReducer, useContext } from "react";

const GlobalStateContext = createContext();
const GlobalDispatchContext = createContext();

export const INIT_GAMES = 'INIT_GAMES';

const globalReducer = (state, action) => {
    switch(action.type) {
        case 'INIT_GAMES': {
            return {
                ...state,
                games: action.payload
            }
        }
        default: {
            throw new Error('Unhandled action type: ' + action.type);
        }
    }
}

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(globalReducer, {
        games: []
    })

    return (
        <GlobalDispatchContext.Provider value={dispatch}>
            <GlobalStateContext.Provider value={state}>
                { children }
            </GlobalStateContext.Provider>
        </GlobalDispatchContext.Provider>
    )
}

export const useGlobalStateContext = () => useContext(GlobalStateContext);
export const useGlobalDispatchContext = () => useContext(GlobalDispatchContext);
