/*
	Feature component is responsible for showing some image and text in a card type information.
*/

import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const filler_image =
	'https://images.unsplash.com/photo-1586527155314-1d25428324ff?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1950&q=80';

const styles = () => ({
	container: {
		borderRadius: 0,
		display: 'flex',
	},
	content: {
		padding: 12,
		margin: 0,
		display: 'flex',
		flexDirection: 'column',
	},
	media: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 8,
		// position: 'relative',
		// overflow: 'hidden',
		height: 'min(max(240px, 30vw), 430px)',
		maxWidth: 350,
		objectFit: 'cover',
	},
	img: {
		height: '100%',
		width: 'auto',
		maxWidth: 'min(max(180px, 25vw), 330px)',
		borderRadius: 16,
		objectFit: 'cover',
		objectPosition: '50% 50%',
	},
	title: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 'min(max(20px, 3vw), 34px)',
	},
	text: {
		textAlign: 'center',
		fontSize: 'min(max(14px, 2vw), 16px)',
	},
});

const F = (props) => {
	let { classes, data, elevation, clickAction, className } = props;
	let { title, description, image, url, color, textColor, backgroundColor } = data;
	return (
		<Card
			className={(className && className) || classes.container}
			onClick={url && clickAction && clickAction(url ? url : '')}
			elevation={elevation || 0}
		>
			<CardContent>
				<CardMedia
					alt={image}
					title={title}
					className={classes.media}
				>
					<img src={(image !== '' && image) || filler_image} className={classes.img} alt="feature"/>
				</CardMedia>
				<CardContent
					style={{ background: backgroundColor }}
					className={classes.content}
				>
					<Typography
						gutterBottom
						variant="h4"
						color={color || 'textPrimary'}
						className={classes.title}
					>
						{title}
					</Typography>
				</CardContent>
				<CardContent
					style={{ background: backgroundColor }}
					className={classes.content}
				>
					<Typography
						variant="body1"
						color={textColor || color || 'textSecondary'}
						className={classes.text}
					>
						{description}
					</Typography>
				</CardContent>
			</CardContent>
		</Card>
	);
};

F.propTypes = {
	classes: PropTypes.object,
	height: PropTypes.number,
	elevation: PropTypes.number,
	data: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.node.isRequired,
		image: PropTypes.node.isRequired,
		url: PropTypes.string,
		backgroundColor: PropTypes.string,
		color: PropTypes.string,
		textColor: PropTypes.string,
	}),
};

const Feature = withStyles(styles)(F);

export default Feature;
