import React, { createContext, useReducer, useContext } from "react";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const USER_AUTHENTICATING = 'USER_AUTHENTICATING';
export const USER_AUTH_ERROR = 'USER_AUTH_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_TOKEN_NOT_FOUND = 'USER_TOKEN_NOT_FOUND';

const globalReducer = (state, action) => {
    switch(action.type) {
        case USER_AUTHENTICATING: {
            console.debug("Authenticating...");
            return {
                ...state,
                user: null,
                userLoading: true,
                userError: null
            }
        }
        case USER_AUTHENTICATED: {
            console.debug("Authenticated");
            return {
                ...state,
                user: action.payload,
                userLoading: false,
                userError: null
            }
        }
        case USER_AUTH_ERROR: {
            console.warn("Auth Error.");
            return {
                ...state,
                user: null,
                userLoading: false,
                userError: action.payload
            }
        }
        case USER_TOKEN_NOT_FOUND: {
            console.warn("Auth token not found.");
            return {
                ...state,
                user: null,
                userLoading: false,
                userError: null
            }
        }
        case USER_LOGOUT: {
            console.debug("Logout");
            return {
                ...state,
                user: null,
                userLoading: false,
                userError: null
            }
        }
        default: {
            throw new Error('Unhandled action type: ' + action.type);
        }
    }
}

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(globalReducer, {
        user: null,
        userLoading: true,
        userError: null
    })

    return (
        <AuthDispatchContext.Provider value={dispatch}>
            <AuthStateContext.Provider value={state}>
                { children }
            </AuthStateContext.Provider>
        </AuthDispatchContext.Provider>
    )
}

export const useAuthStateContext = () => useContext(AuthStateContext);
export const useAuthDispatchContext = () => useContext(AuthDispatchContext);
