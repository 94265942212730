import React from 'react';
import './Footer.scss';
import {useTranslation} from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer>
            <ul className="links">
                <li><a href="https://doppio.games/privacy">{t('portal.privacy_policy')}</a></li>
                <li><a href="https://doppio.games/tos">{t('portal.tos')}</a></li>
                <li><a href="https://doppio.games/support">{t('portal.support')}</a></li>
            </ul>
            <div className="copyright">
                &copy; 2023 Fortis Games Unip Lda
            </div>
        </footer>
    )
}

export default Footer;
