/*
	FeaturesContainer is a container to display features according with the data passed to it in the values property.
*/

import React from 'react';
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Feature from "./Feature";

import {useTranslation} from 'react-i18next';

let styles = () => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	banners: {
		display: 'flex',
		'@media screen and (max-width: 780px)': {
			flexWrap: 'wrap',
		},
		width: '100%',
		marginBottom: 16,
	},
	innerContainer: {
		display: 'flex',
		alignContent: 'center',
		justifyContent: 'space-around',
		width: '100%',
		margin: '20px 0',
	},
	titleContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	title: {
		color: 'rgba(255,255,255,1)',
		fontWeight: 'bold',
		fontSize: 'min(max(24px, 4vw), 56px)',
	},
	feature: {
		backgroundColor: 'transparent',
		color: 'rgba(255,255,255,1)',
		maxWidth: 280,
		minHeight: 360,
		display: 'flex',
		alignItems: 'flex-start',
		borderRadius: 0,
	},
});

const F = props => {
	let features = props.values;
	let { classes } = props;
	const { t } = useTranslation();
	return (
		<div className={classes.container} >
			<div className={classes.titleContainer}>
				<Typography variant="h2" className={classes.title}>{t('features_title').toUpperCase()}</Typography>
			</div>
			<div className={classes.banners}>
				{features.map((feature, i) =>
					<div key={i} className={classes.innerContainer} > 
						<Feature data={feature.data} {...feature} className={classes.feature} />
					</div>
				)}
			</div>
		</div>
	)
}

const FeaturesContainer = withStyles(styles)(F);

export default FeaturesContainer;