import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translations: {
                    profile: {
                        title: 'Profile',
                        icon: 'Current Avatar',
                        gallery: 'Choose your new Avatar:',
                    },
                    features_title: 'Features',
                    features: {
                        unlock: {
                            title: 'Unlock Exclusive Rewards',
                            description: 'Unlock special rewards in all of our games, from fun-looking new spells in Voice Quest to intriguing space artifacts in The Vortex.',
                        },
                        discover: {
                            title: 'Find Amazing Games',
                            description: 'Discover engaging new games made by Doppio, one of the world’s leading voice game developers.',
                        },
                        join: {
                            title: 'Join the Community',
                            description: 'As a VoiceClub member, give your opinion, and help us build the future of voice games.',
                        },
                    },
                    welcome: 'Welcome to VoiceClub',
                    welcome_subtitle: 'Join a community of voice game enthusiasts, unlock exclusive rewards and discover quality voice games.',
                    welcome_signup: 'Sign up below!',
                    login_button: {
                        google: 'Log in with Google',
                        amazon: 'Log in with Amazon',
                    },
                    page_title_cerrado: `Connect <1>The 3% Challenge</1> to your VoiceClub account`,
                    page_title_arpeggio: `Connect <1>The Vortex</1> to your VoiceClub account`,
                    page_title_french: `Connect <1>Voice Quest</1> to your VoiceClub account`,
                    page_title_hot: `Connect <1>Just Say The Word!</1> to your VoiceClub account`,
                    permissions: {
                        cerrado_title: "<1>The 3% Challenge</1> will be able to:",
                        arpeggio_title: "<1>The Vortex</1> will be able to:",
                        french_title: "<1>Voice Quest</1> will be able to:",
                        hot_title: "<1>Just Say The Word!</1> will be able to:",
                        list_line_1: "Access information from your VoiceClub account, including your name and email address;",
                        list_line_2: "Save information, like gameplay stats and achievements, and your in-game purchases, to your VoiceClub account.",
                        login_with_amazon: "Login with Amazon",
                        login_with_google: "Login with Google"
                    },
                    info_line_1: "To confirm this connection, log in to your VoiceClub account by selecting one of the methods below.",
                    info_line_2: "If you don't have a VoiceClub account already, we'll create one for you when you log in.",
                    privacy_links: "Please review our <1>privacy policy</1> and <3>terms of service</3>.",
                    privacy_url: "https://doppio.games/privacy",
                    tos_url: "https://doppio.games/tos",
                    portal: {
                        title: 'Games Portal',
                        logout: 'Log out',
                        login_title: 'Hi.',
                        login_line_1: 'Log in to your VoiceClub account by selecting one of the methods below.',
                        login_line_2: 'If you don\'t have a VoiceClub account already, we\'ll create one for you when you log in.',
                        press_info: 'Press Info',
                        privacy_policy: 'Privacy Policy',
                        tos: 'Terms of Service',
                        support: 'Support',
                        games_list: 'My Games',
                        play_google: 'Play with Google',
                        play_alexa: 'Play with Alexa',
                        play_zoom: 'Play on Zoom',
                        last_played: 'Last played: ',
                        never_played: 'Never played',
                        arpeggio: {
                            title: 'The Vortex',
                            description: 'Command a fleet of spaceships and explore The Vortex',
                            collectibles: 'Check your collectibles here',
                            collectibles_title: 'Your Collection',
                            nothing_found: 'You don\'t have any collectibles yet! Explore the Vortex to find your first one!',
                            some_found: "You have collected {{count}} / 40 collectibles. Keep exploring the Vortex to find them all.",
                            all_found: "You have found all 40 collectibles, congratulations!"

                        },
                        cerrado: {
                            title: 'The 3% Challenge',
                            description: 'Pass through The Process, win all challenges to become part of the Top 3%.',
                            collectibles: ' ',
                            collectibles_title: 'The 3% Collection'
                        },
                        french: {
                            title: 'Voice Quest',
                            description: 'Help Merlin take back Camelot and save King Arthur by casting wondrous spells',
                            avatars: 'Update your Avatar here!',
                            avatars_title: 'Voice Quest Avatar',
                            alert_success: 'Avatar updated!',
                            alert_error: 'There was an error updating your avatar. Please try again later',
                        },
                        hot: {
                            title: 'Just Say The Word!',
                            description: 'Team up with friends to think of creative ways to describe as many words as possible!',
                            cards_label: 'Cards'
                        },
                    }
                }
            },
            pt: {
                translations: {
                    profile: {
                        title: 'Perfil',
                        icon: 'Avatar Atual',
                        gallery: 'Escolha o seu novo Avatar:',
                    },
                    features_title: 'Funcionalidades',
                    features: {
                        unlock: {
                            title: 'Ganhe Benefícios Exclusivos',
                            description: 'Adquira benefícios exclusivos em todos os nossos jogos, desde magias divertidas em Voice Quest até artefatos espaciais no Vortex.',
                        },
                        discover: {
                            title: 'Descubra Jogos Fantásticos',
                            description: 'Descubra novos jogos divertidos feitos pela Doppio, um dos líderes globais em jogos de voz.',
                        },
                        join: {
                            title: 'Se una à Comunidade',
                            description: 'Como membro do VoiceClub, compartilhe as suas opiniões e nos ajude a construir o futuro dos jogos de voz.',
                        },
                    },
                    welcome: 'Boas-vindas ao VoiceClub',
                    welcome_subtitle: 'Se una à uma comunidade de entusiastas de jogos de voz, adquira benefícios exclusivos, e descubra jogos de voz de qualidade.',
                    welcome_signup: 'Entre agora!',
                    login_button: {
                        google: 'Entrar com conta Google',
                        amazon: 'Entrar com conta Amazon',
                    },
                    page_title_cerrado: `Conecte <1>O Desafio 3%</1> à sua conta VoiceClub`,
                    page_title_arpeggio: `Conecte <1>O Vortex</1> à sua conta VoiceClub`,
                    page_title_french: `Conecte <1>Voice Quest</1> à sua conta VoiceClub`,
                    permissions: {
                        cerrado_title: "<1>O Desafio 3%</1> vai poder:",
                        arpeggio_title: "<1>O Vortex</1> vai poder:",
                        french_title: "<1>Voice Quest</1> vai poder:",
                        list_line_1: "Acessar informação da sua conta VoiceClub, incluindo o seu nome e email;",
                        list_line_2: "Guardar de forma segura a sua informação (tais como estatísticas e achievements) e compras na sua conta VoiceClub.",
                        login_with_amazon: "Login com Amazon",
                        login_with_google: "Login com Google"
                    },
                    info_line_1: "Para confirmar esta conexão, entre na sua conta VoiceClub usando um dos métodos abaixo.",
                    info_line_2: "Se você não tem uma Doppio ID ainda, vamos criar uma para você automaticamente quando você entrar.",
                    privacy_links: "Por favor leia a nossa <1>política de privacidade</1> e <3>termos de serviço</3>.",
                    privacy_url: "https://doppio.games/privacy/pt-br",
                    tos_url: "https://doppio.games/tos/pt-br",
                    portal: {
                        title: 'Portal de Jogos',
                        logout: 'Sair',
                        login_title: 'Olá.',
                        login_line_1: 'Entre na sua conta VoiceClub usando um dos métodos abaixo.',
                        login_line_2: 'Se você não tem uma conta VoiceClub ainda, vamos criar uma para você automaticamente quando você entrar.',
                        press_info: 'Imprensa',
                        privacy_policy: 'Política de privacidade',
                        tos: 'Termos de Serviço',
                        support: 'Suporte',
                        games_list: 'Meus Jogos',
                        play_google: 'Jogar com o Google',
                        play_alexa: 'Jogar com a Alexa',
                        last_played: 'Última sessão: ',
                        never_played: 'Nunca jogado',
                        arpeggio: {
                            title: 'O Vortex',
                            description: 'Comande uma frota de naves espaciais e explore O Vortex',
                            collectibles: 'Veja a sua coleção aqui',
                            collectibles_title: 'Sua coleção',
                            nothing_found: 'Você ainda não tem nenhum artefato! Explore O Vortex para começar a sua coleção.',
                            some_found: "Você tem {{count}} / 40 artefatos em sua coleção. Continue explorando o Vortex para achar todos.",
                            all_found: "Você tem todos os 40 artefatos existentes em sua coleção, parabéns!"
                        },
                        cerrado: {
                            title: 'O Desafio 3%',
                            description: 'Experiente o Processo, e vença os desafios para se tornar parte dos 3%.',
                            collectibles: ' ',
                            collectibles_title: 'A Coleção 3%'
                        },
                        french: {
                            title: 'Voice Quest',
                            description: 'Solte magias criativas para ajudar Merlin a salvar Camelot e o Rei Arthur',
                            avatars: 'Atualize o seu Avatar!',
                            avatars_title: 'Avatar para Voice Quest',
                            alert_success: 'Avatar atualizado!',
                            alert_error: 'Um erro acontenceu durante a tentativa de atualizar o seu Avatar. Por favor tente de novo mais tarde',
                        },
                        hot: {
                            cards_label: 'Cartas',
                            description: 'Junte-se com seus amigos para descrever de forma criativa o maior número de palavras possível!'
                        },
                    }
                }
            },
            es: {
                translations: {
                    profile: {
                        title: 'Perfil',
                        icon: 'Avatar actual',
                        gallery: 'Escoge nuevo avatar:',
                    },
                    features_title: 'Características',
                    features: {
                        unlock: {
                            title: 'Desbloquea recompenzas exclusivas',
                            description: 'Desbloquea recompenzas exclusivas de los juegos, desde nuevos divertidos hechizos en Voice Quest hasta misteriosos artefactos espaciales en The Vortex.',
                        },
                        discover: {
                            title: 'Descubre juegos increíbles',
                            description: 'Descubre más emocionantes juegos creados por Doppio, uno de los estudios líderes en el desarrollo de juegos de voz a nivel mundial.',
                        },
                        join: {
                            title: 'Únete a la comunidad',
                            description: 'Como miembro del VoiceClub déjanos tu opinión para ayudar a crear el futuro de los juegos de voz.',
                        },
                    },
                    welcome: 'Bienvenido a VoiceClub',
                    welcome_subtitle: 'Únete a la comunidad de entusiastas de los juegos de voz, desbloquea premios exclusivos y descubre juegos de voz de calidad.',
                    welcome_signup: '¡Ingresa a continuación!',
                    login_button: {
                        google: 'Ingresar con Google',
                        amazon: 'Ingresar con Amazon',
                    },
                    page_title_cerrado: `Conecta <1>The 3% Challenge</1> a tu cuenta VoiceClub`,
                    page_title_arpeggio: `Conecta <1>El Vortex</1> a tu cuenta VoiceClub`,
                    page_title_french: `Conecta <1>Voice Quest</1> a tu cuenta VoiceClub`,
                    permissions: {
                        cerrado_title: "<1>The 3% Challenge</1> podrá:",
                        arpeggio_title: "<1>El Vortex</1> podrá:",
                        french_title: "<1>Voice Quest</1> podrá:",
                        list_line_1: "Acceder a la información de tu cuenta VoiceClub, incluyendo su nombre y email;",
                        list_line_2: "Guardar la información (como estadísticas y achievements) y compras en el juego en tu cuenta VoiceClub",
                        login_with_amazon: "Iniciar con Amazon",
                        login_with_google: "Iniciar con Google"
                    },
                    info_line_1: "Para confirmar esta conexión, inicia sesión en tu cuenta VoiceClub seleccionando uno de los métodos a abajo.",
                    info_line_2: "Si aún no tienes una cuenta VoiceClub, crearemos una para ti cuando inicies sesión.",
                    privacy_links: "Por favor lee nuestra <1>política de privacidad</1> y <3>términos de servicio</3>.",
                    privacy_url: "https://doppio.games/privacy/es",
                    tos_url: "https://doppio.games/tos/es",
                    portal: {
                        title: 'Games Portal',
                        logout: 'Terminar sesión',
                        login_title: 'Hola',
                        login_line_1: 'Inicia sesión en tu cuenta VoiceClub seleccionando uno de los métodos a abajo.',
                        login_line_2: 'Si aún no tienes una cuenta VoiceClub, crearemos una para ti cuando inicies sesión.',
                        press_info: 'Información de prensa',
                        privacy_policy: 'Política de privacidad',
                        tos: 'Términos de servicio',
                        support: 'Soporte',
                        games_list: 'Mis Juegos',
                        play_google: 'Jugar en Google',
                        play_alexa: 'Jugar en Alexa',
                        last_played: 'Última sesión: ',
                        never_played: 'Nunca jugado',
                        arpeggio: {
                            title: 'El Vortex',
                            description: 'Controla una flotilla de naves espaciales y explora The Vortex',
                            collectibles: 'Revisa tus coleccionables aquí',
                            collectibles_title: 'Mi colección',
                            nothing_found: '¡Aún no tienes ningún coleccionable! ¡Encuentra el primero explorando el Vortex!',
                            some_found: "Has encontrado {{count}} / 40 coleccionables. Continúa explorando el Vortex para encontrarlos todos.",
                            all_found: "¡Felicidades, has encontrado todos los 40 coleccionables!"
                        },
                        cerrado: {
                            title: 'The 3% Challenge',
                            description: 'Aprueba el proceso y vence todos los desafíos para ser parte del Top 3%.',
                            collectibles: ' ',
                            collectibles_title: 'Colección del 3%'
                        },
                        french: {
                            title: 'Voice Quest',
                            description: 'Ayuda Merlín a retomar Camelot lanzando asombrosos hechizos y salvar al rey Arturo',
                            avatars: '¡Actualiza tu avatar aquí!',
                            avatars_title: 'Avatar de Voice Quest',
                            alert_success: '¡Avatar actualizado!',
                            alert_error: 'Hubo un error al actualizar el avatar. Por favor intenta más tarde.',
                        },
                        hot: {
                            cards_label: 'Cartas',
                            description: '¡Júntate con un grupo de amigos para describir de manera creativa tantas palabras como sea posible!'
                        }
                    }
                }
            }
        },
        fallbackLng: 'en',
        ns: ["translations"],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true
        },
        detection: {
            lookupQuerystring: "user_locale"
        }
    })

export default i18n;
