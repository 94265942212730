import React, {useEffect} from 'react';
import './GamesContainer.scss';
import i18next from "i18next";
import {useAuthStateContext} from "../../contexts/auth-context";
import moment from "moment";
import {useTranslation} from "react-i18next";
import Game from './Game';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        alignItems: 'center',
        // justifyContent: 'center',
        width: '100%',
        height: 'auto',
        minHeight: 'calc(100vh - 245px - 329px)',
        margin: '0px 0px 50px 0px',
    },
    li: {
        listStyleType: 'none',
    },
    divider: {
        margin: '80px 0',
        '@media screen and (max-width: 936px)': {
            margin: '20px 10px',
        },
        backgroundColor: '#FFF',
    },
});

const GamesContainer = (props) => {

    let { classes, games } = props;

    const { user } = useAuthStateContext();
    const { t } = useTranslation();

    const playWithZoom = (clientId) => {
        const amplitude = window.amplitude;
        amplitude.getInstance().logEvent('ClickToPlay', {
            game_id: clientId,
            platform_id: 'zoom'
        });

        switch (clientId) {
            case 'hot': {
                switch (i18next.language) {
                    default:
                        return window.open('https://zoom.us/oauth/authorize?response_type=code&client_id=KqBBfNYuTZe9EP3iqBRYQ&redirect_uri=https://za.justsaytheword.live/za-redirect')
                }
            }
            default:
                return null;
        }
    }

    const playWithAlexa = (clientId) => {
        const amplitude = window.amplitude;
        amplitude.getInstance().logEvent('ClickToPlay', {
            game_id: clientId,
            platform_id: 'amazon'
        });

        switch (clientId) {
            case 'cerrado': {
                switch (i18next.language) {
                    case 'en':
                    case 'en-US':
                        return window.open('https://doppio.games/three-percent-alexa')
                    case 'pt-BR':
                        return window.open('https://www.amazon.com.br/Doppio-Games-O-Desafio-3/dp/B07YTV91CY')
                    default:
                        return window.open('https://www.amazon.com/Doppio-Games-The-3-Challenge/dp/B07YTV91CY')
                }
            }
            case 'arpeggio': {
                switch (i18next.language) {
                    case 'en':
                    case 'en-US':
                        return window.open('https://doppio.games/the-vortex-alexa')
                    case 'pt-BR':
                        return window.open('https://www.amazon.com.br/Doppio-Games-The-Vortex/dp/B07JJV13G3')
                    default:
                        return window.open('https://www.amazon.com/Doppio-Games-The-Vortex/dp/B07JJV13G3')
                }
            }
            default:
                return null;
        }
    }

    const playWithGoogle = (clientId) => {
        const amplitude = window.amplitude;
        amplitude.getInstance().logEvent('ClickToPlay', {
            game_id: clientId,
            platform_id: 'google'
        });

        switch (clientId) {
            case 'cerrado':
                switch (i18next.language) {
                    case 'en':
                    case 'en-US':
                        return window.open('https://assistant.google.com/services/invoke/uid/000000034440416a?hl=en')
                    case 'pt-BR':
                        return window.open('https://assistant.google.com/services/invoke/uid/000000034440416a?hl=pt-BR')
                    default:
                        return window.open('https://assistant.google.com/services/invoke/uid/000000034440416a?hl=en')
                }
            case 'arpeggio':
                switch (i18next.language) {
                    case 'en':
                    case 'en-US':
                        return window.open('https://assistant.google.com/services/invoke/uid/000000a4f1e24d48?hl=en')
                    case 'pt-BR':
                        return window.open('https://assistant.google.com/services/invoke/uid/000000a4f1e24d48?hl=pt-BR')
                    case 'es':
                        return window.open('https://assistant.google.com/services/invoke/uid/000000a4f1e24d48?hl=es')
                    default:
                        return window.open('https://assistant.google.com/services/invoke/uid/000000a4f1e24d48?hl=en')
                }
            case 'french': 
                return window.open('https://assistant.google.com/services/invoke/uid/000000376447d35e?hl=en')
            default: {
                return null
            }
        }
    }

    const getNumHotCards = () => {
        if(user.gameProperties && user.gameProperties.hot && user.gameProperties.hot.cards && Array.isArray(user.gameProperties.hot.cards)) {
            return user.gameProperties.hot.cards.length;
        } else {
            return 0;
        }
    }

    const getLastPlayed = (clientId) => {
        moment.locale(i18next.language);

        if (user.links && user.links.length > 0) {
            let lastPlayed = [];

            for (let idx = 0; idx < user.links.length; idx++) {
                if (user.links[idx].client_id !== clientId) {
                    continue;
                }

                if (user.links[idx].hasOwnProperty('lastPlayed')) {
                    lastPlayed.push(moment(user.links[idx].lastPlayed));
                }
            }

            if (lastPlayed.length > 0) {
                const lastPlayedMax = lastPlayed.reduce((a, b) => moment.max(a, b)).startOf('hour').fromNow();
                return t('portal.last_played') + lastPlayedMax;
            } else {
                return t('portal.never_played');
            }
        }
    }

    useEffect(() => {
        // console.log('games user', user);
    }, [user])

    return (
        <div className={classes.container}>
            {games.map((game, i) => {
                let game_data = {
                    ...game,
                    play_alexa: t('portal.play_alexa'),
                    play_google: t('portal.play_google'),
                    play_zoom: t('portal.play_zoom'),
                    playWithAlexa: game.alexa !== false ? () => playWithAlexa(game.id) : undefined,
                    playWithGoogle: game.google !== false ? () => playWithGoogle(game.id) : undefined,
                    playWithZoom: game.zoom !== false ? () => playWithZoom(game.id) : undefined,
                    getLastPlayed: () => getLastPlayed(game.id),
                    getNumHotCards: () => getNumHotCards()
                }
                return (
                <li key={game.id} className={classes.li}>
                    <Game {...game_data} />
                    {i !== (games.length - 1) && <Divider variant="fullWidth" className={classes.divider} />}
                </li>
            )})}
        </div>
    )
}

export default withStyles(styles)(GamesContainer);
