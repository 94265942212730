import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Loader from "../components/loader/Loader";

const PrivateRoute = ({ component: Component, auth, loading, ...rest}) => (
    <>
        {
            !loading ? (
                <Route {...rest} render={props => (
                    auth !== null
                        ? <Component {...props} />
                        : <Redirect to="/login"/>
                )}/>
            ) : <Loader/>
        }
    </>
)

export default PrivateRoute;
